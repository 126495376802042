import './toastMessage.css';

export const ToastMessage = ({ title, text, text2, link }) => {
    return (
        <div className="toast-message-container">
            {title && <h4>{title}</h4>}
            {text && <p>{text}</p>}
            {text2 && <p>{text2}</p>}
            {link?.href && link?.text && (
                <a
                    href={link.href}
                    target="_blank"
                    rel="noreferrer"
                    className="toast-link"
                >
                    {link.text}
                </a>
            )}
        </div>
    );
};