import { useEffect, useState } from "react";
import { ThreeCircles } from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import { buyNFT } from "../../utils/marketplace"; // Ensure listNFTForSale is imported if needed
import { toastConfig, createBlockExplorerUrl } from "../../utils/helpers";
import { toast } from "react-toastify";
import { ToastMessage } from "../../components/toast/ToastMessage";
import "./item.css";
import { quais } from "quais";
import marketplace from "../../Marketplace.json";

const Item = ({ provider, user, fetchAllNFTs, isCyprus1 }) => {
  const [loading, setLoading] = useState(false);
  const [isUserOwner, setIsUserOwner] = useState(false);
  const [showResellModal, setShowResellModal] = useState(false); // State for resell modal
  const [resellPrice, setResellPrice] = useState(""); // State for resell price
  const location = useLocation();
  const nftItem = location.state.data;

  // Update owner check whenever user or seller changes
  useEffect(() => {
    if (user && nftItem.seller && user.addr.toLowerCase() === nftItem.seller.toLowerCase()) {
      setIsUserOwner(true);
    } else {
      setIsUserOwner(false);
    }
  }, [user, nftItem.seller]);

  // Function to purchase NFT
  async function purchaseNFT() {
    setLoading(true);
    try {
      console.log("Attempting to purchase NFT with token ID:", nftItem.tokenId);

      // Purchase NFT
      const buyResponse = await buyNFT(provider.web3Provider, nftItem.tokenId, nftItem.price);
      console.log("Full response from buyNFT:", buyResponse);

      if (buyResponse.status === "success") {
        const transactionHash = buyResponse.data;
        console.log("Transaction hash:", transactionHash);

        // Wait for confirmation
        const receipt = await provider.rpcProvider.waitForTransaction(transactionHash);
        console.log("Transaction receipt:", receipt);

        if (receipt && receipt.status === 1) {
          toast(
            <ToastMessage
              title="Purchase Successful"
              link={{ href: createBlockExplorerUrl("transaction", receipt.transactionHash), text: "View on Explorer" }}
            />,
            toastConfig
          );

          // Update owner state
          setIsUserOwner(true);
          fetchAllNFTs(provider.rpcProvider);
        } else {
          toast(
            <ToastMessage
              title="Purchase Failed"
              text="Transaction reverted or logs are empty."
              link={{ href: createBlockExplorerUrl("transaction", transactionHash), text: "View on Explorer" }}
            />,
            toastConfig
          );
        }
      } else {
        throw new Error(buyResponse.data || "Unknown error during purchase.");
      }
    } catch (error) {
      console.error("Error during NFT purchase:", error);
      alert(`Error during purchase: ${error.message || error.toString()}`);
    } finally {
      setLoading(false);
    }
  }

  // Function to list NFT for resale
  async function handleResell() {
	setLoading(true);
	setShowResellModal(false);
  
	try {
	  const price = quais.utils.parseUnits(resellPrice.toString(), "ether");
	  console.log("Parsed price for resell:", price.toString());
  
	  const contract = new quais.Contract(
		marketplace.address,
		marketplace.abi,
		provider.web3Provider.getSigner()
	  );
  
	  // List NFT for sale
	  const tx = await contract.updateToken(nftItem.tokenId, price, true);
	  console.log("Transaction sent:", tx);
  
	  const receipt = await tx.wait();
	  console.log("Transaction confirmed:", receipt);
  
	  if (receipt.status === 1) {
		toast(
		  <ToastMessage
			title="NFT listed successfully"
			link={{
			  href: createBlockExplorerUrl("transaction", receipt.transactionHash),
			  text: "View on Explorer",
			}}
		  />,
		  toastConfig
		);
  
		// Directly update nftItem's price and onSale status
		nftItem.price = resellPrice; // Update the price in the current object
		nftItem.onSale = true; // Ensure the status reflects the sale
	  } else {
		throw new Error("Transaction failed or was reverted.");
	  }
	} catch (error) {
	  console.error("Error during NFT resell:", error);
	  if (error.code === 4001) {
		alert("Transaction was rejected.");
	  } else {
		alert(`Error during resell: ${error.message || "Unknown error occurred"}`);
	  }
	} finally {
	  setLoading(false);
	}
  }

  // JSX for resell modal
  const resellModal = showResellModal && (
    <div className="modal">
      <div className="modal-content">
        <h2>Set a price to list your NFT</h2>
        <input
          type="text"
          placeholder="Enter price in QUAI"
          value={resellPrice}
          onChange={(e) => setResellPrice(e.target.value)}
        />
        <button onClick={handleResell}>List NFT For Sale</button>
        <button onClick={() => setShowResellModal(false)}>Cancel</button>
      </div>
    </div>
  );

  return (
    <div className="item section__padding">
      <div className="item-image-container">
        <img src={nftItem.image} alt="item" />
        {resellModal}
      </div>
      <div className="item-content">
        <div className="item-content-title">
          <h1>{nftItem.name}</h1>
          <h2 className="item-title">{nftItem.price} QUAI</h2>
        </div>
        <div className="item-content-creator">
          <p className="item-title">Contract Address</p>
          <a
            href={createBlockExplorerUrl("address", nftItem.owner)}
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            {nftItem.owner}
          </a>
        </div>
        <div className="item-content-creator">
          <p className="item-title">Owner</p>
          <a
            href={createBlockExplorerUrl("address", nftItem.seller)}
            target="_blank"
            rel="noreferrer"
            className="link"
          >
            {isUserOwner ? "Me" : nftItem.seller}
          </a>
        </div>
        <div className="item-content-creator">
          <p className="item-title">Description</p>
          {nftItem.description}
        </div>
        <div className="item-content-creator">
          <p className="item-title">Availability</p>
          {nftItem.onSale ? "On sale" : "Private"}
        </div>
        <div className="item-content-buy">
          {!loading && (
            <>
              {isUserOwner ? (
                <button
                  onClick={() => setShowResellModal(true)}
                  className="primary-btn"
                  disabled={loading || !isUserOwner}
                >
                  {nftItem.onSale ? "Change Price" : "List for Sale"}
                </button>
              ) : (
                <button
                  onClick={() => purchaseNFT()}
                  className="primary-btn"
                  disabled={!isCyprus1 || !nftItem.onSale}
                >
                  Buy
                </button>
              )}
            </>
          )}
          {loading && (
            <ThreeCircles
              visible={true}
              height="90"
              width="90"
              color="#EB1484"
              ariaLabel="three-circles-loading"
              wrapperStyle={{ justifyContent: "center" }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Item;