import axios from 'axios'
import MarketplaceJSON from '../Marketplace.json'
import { GetIpfsUrlFromPinata } from './helpers'
import { quais } from 'quais'


// hits the rpcProvider to get all NFTs, sort the data, and return it
export const getAllNFTs = async (provider) => {
	const contract = new quais.Contract(MarketplaceJSON.address, MarketplaceJSON.abi, provider)
	const NFTs = await contract.getAllNFTs()
	const NFTItems = await Promise.all(
		NFTs
		.filter(async (NFT) => {
			return NFT.listed;
		  })
		  .map(async (NFT) => {
			var tokenURI = await contract.tokenURI(NFT.tokenId)
			tokenURI = GetIpfsUrlFromPinata(tokenURI)
			const metadata = await axios.get(tokenURI)
			return {
				price: quais.utils.formatEther(NFT.price),
				tokenId: NFT.tokenId.toNumber(),
				seller: NFT.seller,
				owner: NFT.owner,
				onSale: NFT.onSale,
				name: metadata.data.name,
				description: metadata.data.description,
				image: metadata.data.image,
			}
		})
	)
	return NFTItems
}

// hits the web3provider to prompt user to confirm purchase of NFT, returns transaction status
export const buyNFT = async (provider, tokenId, price) => {
	let res
	const contract = new quais.Contract(MarketplaceJSON.address, MarketplaceJSON.abi, provider.getSigner())
	await contract
		.executeSale(tokenId, {
			value: quais.utils.parseEther(price),
			gasLimit: quais.utils.hexlify(150000),
		})
		.then((tx) => {
			res = { status: 'success', data: tx.hash }
		})
		.catch((err) => {
			res = { status: 'error', data: err }
		})

	return res
};
  
  
// hits the web3provider to prompt user to confirm listing of NFT, returns transaction status
export const createNFT = async (provider, metadataURL, price) => {
    try {
        const contract = new quais.Contract(MarketplaceJSON.address, MarketplaceJSON.abi, provider.getSigner());
        const listingPrice = await contract.getListingPrice();
        console.log("Listing price:", listingPrice.toString()); // Логируем стоимость листинга
        
        const tx = await contract.createToken(metadataURL, price, true, {
            value: listingPrice.toString(),
            gasLimit: quais.utils.hexlify(350000),
        });

        console.log("Transaction sent:", tx);
        return { status: "success", data: tx.hash }; // Возвращаем хэш транзакции
    } catch (error) {
        console.error("Error during createNFT:", error);
        return { status: "error", data: error.message || error };
    }
};
  

// Function to list an NFT for sale
export const listNFTForSale = async (provider, tokenId, priceInQuai, setLoading) => {
    let res;
    console.log(`Attempting to list NFT for sale. Token ID: ${tokenId}, Price in QUAI: ${priceInQuai}`);

    try {
        // Start loader
        setLoading(true);

        // Validate price
        if (!priceInQuai || isNaN(priceInQuai) || Number(priceInQuai) <= 0) {
            console.error("Invalid price provided:", priceInQuai);
            setLoading(false);
            return { status: "error", data: { message: "Invalid price provided" } };
        }

        const contract = new quais.Contract(MarketplaceJSON.address, MarketplaceJSON.abi, provider.getSigner());
        const tokenOwner = await contract.ownerOf(tokenId);
        const signerAddress = await provider.getSigner().getAddress();

        if (signerAddress !== tokenOwner) {
            console.error("The signer is not the owner of the NFT.");
            setLoading(false);
            return { status: "error", data: { message: "You must own the NFT to list it for sale." } };
        }

        const parsedPrice = quais.utils.parseUnits(priceInQuai.toString(), "ether");
        console.log("Parsed price for listing:", parsedPrice.toString());

        const tx = await contract.updateToken(tokenId, parsedPrice, true, {
            gasLimit: quais.utils.hexlify(300000),
        });
        console.log("Transaction sent:", tx);

        const receipt = await tx.wait();
        console.log("Transaction confirmed:", receipt);

        res = { status: "success", data: receipt.transactionHash };
    } catch (error) {
        console.error("Error during listing:", error);
        res = { status: "error", data: error };
    } finally {
        // Stop loader in all cases
        setLoading(false);
    }

    return res;
};


