import { useEffect, useState } from "react";
import { quais } from "quais";
import { uploadFileToIPFS, uploadJSONToIPFS } from "../../utils/pinata";
import { ThreeCircles } from "react-loader-spinner";
import { createNFT } from "../../utils/marketplace";
import { toast } from "react-toastify";
import "./create.css";
import { ToastMessage } from '../../components/toast/ToastMessage';


const Create = ({ provider, fetchAllNFTs, isCyprus1 }) => {
  const [formParams, updateFormParams] = useState({ name: "", description: "", price: "" });
  const [fileURL, setFileURL] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("Current loading state:", loading);
  }, [loading]);

  async function waitForTransactionConfirmation(txHash, provider) {
    console.log(`Waiting for transaction confirmation: ${txHash}`);
    let attempts = 0;
    const maxAttempts = 60; // Максимум 60 попыток по 1.5 сек

    while (attempts < maxAttempts) {
        const receipt = await provider.getTransactionReceipt(txHash);

        if (receipt) {
            console.log("Transaction receipt found:", receipt);
            if (receipt.status === 1) {
                return receipt; // Успех
            } else {
                throw new Error("Transaction failed or reverted.");
            }
        }

        // Ждем 1.5 секунды перед повторной проверкой
        console.log(`No receipt yet, retrying... Attempt ${attempts + 1}`);
        await new Promise((resolve) => setTimeout(resolve, 1500));
        attempts++;
    }

    throw new Error("Transaction confirmation timed out.");
  }

  async function OnChangeFile(e) {
    setLoading(true);
    const file = e.target.files[0];
    try {
      const response = await uploadFileToIPFS(file);
      if (response.success) {
        setFileURL(response.pinataURL);
      } else {
        toast.error("File upload failed!");
      }
    } catch (error) {
      console.error("Error during file upload:", error);
    } finally {
      setLoading(false);
    }
  }

  async function uploadMetadataToIPFS() {
    const { name, description, price } = formParams;
    if (!name || !description || !price || !fileURL) {
      toast.error("Please fill all fields and upload a file.");
      return -1;
    }
    const nftJSON = { name, description, price, image: fileURL };
    try {
      const response = await uploadJSONToIPFS(nftJSON);
      if (response.success) {
        return response.pinataURL;
      } else {
        toast.error("Metadata upload failed!");
        return -1;
      }
    } catch (error) {
      console.error("Error uploading metadata:", error);
      toast.error("Error uploading metadata to IPFS.");
      return -1;
    }
  }

  async function listNFT(e) {
    e.preventDefault();
    setLoading(true);

    const toastId = toast.info("Transaction submitted. Waiting for confirmation...", {
        position: "top-right",
        autoClose: false,
        closeOnClick: false,
    });

    try {
        // Шаг 1: Загрузка метаданных
        const metadataURL = await uploadMetadataToIPFS();
        if (metadataURL === -1) {
            throw new Error("Metadata upload failed. Please fill all fields and try again.");
        }

        // Шаг 2: Отправка транзакции
        const price = quais.utils.parseUnits(formParams.price, "ether");
        console.log("Parsed price:", price.toString());

        const response = await createNFT(provider.web3Provider, metadataURL, price);

        if (response.status === "success") {
            console.log("Transaction sent. Hash:", response.data);

            // Шаг 3: Ожидание подтверждения транзакции
            const receipt = await waitForTransactionConfirmation(response.data, provider.rpcProvider);

            console.log("Transaction confirmed:", receipt);

            // Шаг 4: Обновление уведомления о завершении
            toast.update(toastId, {
                render: (
                    <ToastMessage
                        title="🎉 Transaction Confirmed!"
                        text="Your NFT has been successfully listed on the marketplace."
                        link={{
                            href: `https://quaiscan.io/tx/${receipt.transactionHash}`,
                            text: "View on Explorer",
                        }}
                    />
                ),
                type: "success",
                autoClose: 5000,
            });

            // Шаг 5: Обновление списка NFT
            fetchAllNFTs(provider.rpcProvider);

            // Шаг 6: Очистка формы
            updateFormParams({ name: "", description: "", price: "" });
            setFileURL(null);
        } else {
            throw new Error(response.data || "NFT creation failed.");
        }
    } catch (error) {
        console.error("Error during NFT creation:", error);

        if (error.code === 4001) {
            // Если пользователь отклонил транзакцию
            toast.update(toastId, {
                render: "Transaction was rejected by the user.",
                type: "error",
                autoClose: 5000,
            });
        } else {
            // Обработка других ошибок
            toast.update(toastId, {
                render: error.message || "An unexpected error occurred.",
                type: "error",
                autoClose: 5000,
            });
        }
    } finally {
        setLoading(false);
    }
}

  return (
    <div className="create section__padding">
      <div className="create-container">
        <h1>Create new NFT</h1>
        <form className="writeForm" autoComplete="off">
          <div className="formGroup">
            <label>Name</label>
            <input
              type="text"
              placeholder="NFT Name"
              onChange={(e) => updateFormParams({ ...formParams, name: e.target.value })}
              value={formParams.name}
              disabled={!isCyprus1}
              className="input-handler"
            />
          </div>
          <div className="formGroup">
            <label>Description</label>
            <textarea
              rows={4}
              placeholder="Description of your NFT"
              value={formParams.description}
              onChange={(e) => updateFormParams({ ...formParams, description: e.target.value })}
              disabled={!isCyprus1}
              className="input-handler"
            ></textarea>
          </div>
          <div className="formGroup">
            <label>Price</label>
            <input
              type="number"
              placeholder="Min 0.01 QUAI"
              step="0.01"
              value={formParams.price}
              onChange={(e) => updateFormParams({ ...formParams, price: e.target.value })}
              disabled={!isCyprus1}
              className="input-handler"
            />
          </div>
          <div className="formGroup">
            <label>Upload</label>
            <input
              type="file"
              className="custom-file-input input-handler"
              onChange={OnChangeFile}
              disabled={!isCyprus1}
            />
          </div>
          {loading ? (
            <div>
              <p>Transaction is being processed...</p>
              <ThreeCircles visible height="90" width="90" color="#EB1484" />
            </div>
          ) : (
            <button className="writeButton primary-btn" onClick={listNFT} disabled={!isCyprus1}>
              List My NFT
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default Create;