import { getShardFromAddress } from 'quais/lib/utils'

// requestAccounts should be triggered by a user action, prompts connection request via extension pop-up
// if user accepts, account is returned
// if user denies, a 4001 error is printed to the console and nothing happens on the user side, undefined is returned
export const requestAccounts = async () => {
    const { pelagus } = window;

    if (!pelagus) {
        console.error("Pelagus Wallet is not available. Ensure it is installed and running.");
        return undefined;
    }

    if (typeof pelagus.request !== "function") {
        console.error("The provider does not support the `request` method. Check the provider object.");
        console.log("Provider details:", pelagus);
        return undefined;
    }

    try {
        const accounts = await pelagus.request({ method: "quai_requestAccounts" });
        if (accounts && accounts.length > 0) {
            return {
                addr: accounts[0],
                shard: getShardFromAddress(accounts[0]),
            };
        } else {
            console.warn("No accounts found.");
            return undefined;
        }
    } catch (err) {
        console.error("Error requesting accounts:", err);
        return undefined;
    }
};

// getAccounts should only run in the background, checks for an existing wallet connection
// if user is connected, account is returned
// if user is not connected, undefined is returned
export const getAccounts = async (provider) => {
	let account
	await provider
		.send('quai_accounts')
		.then((accounts) => {
			if (accounts.length !== 0) {
				account = {
					addr: accounts[0],
					shard: getShardFromAddress(accounts[0]),
				}
			} else {
				account = undefined
			}
		})
		.catch((err) => {
			console.log('Error getting accounts.', err)
			console.error(err)
		})
	return account
}
